<template>
  <!-- 禁用 naive-ui 的全局样式，参考 https://www.naiveui.com/zh-CN/os-theme/docs/style-conflict  -->
  <n-config-provider preflight-style-disabled>
    <DynamicDialog />

    <!--
    entry 中的 relative 是用来给弹窗定位的
    在 pc 上开启移动端模拟时会使用到
  -->
    <div
      id="app-entry"
      ref="entryRef"
      class="g-h-screen relative flex flex-col"
    >
      <DebugFloatingBall />

      <AppDownloadHeader
        v-if="!_global.isInsideApp && !route.meta.hideAppDownloadHeader"
      />

      <div class="h-fit flex-1 overflow-hidden">
        <RouterView />
      </div>
    </div>
  </n-config-provider>
</template>

<script setup lang="ts">
import { h, onMounted, onUnmounted, ref } from 'vue'
import DynamicDialog from 'primevue/dynamicdialog'
import { useDialog } from 'primevue/usedialog'
import { setViewportHeight } from '../utils'
import { NConfigProvider } from 'naive-ui'

import Confirm from '@/components/Confirm.vue'
import Notice from '@/components/Notice.vue'
import InputText from 'primevue/inputtext'
import Button from '@/components/Button.vue'
import DebugFloatingBall from '@/components/DebugFloatingBall.vue'
import NotEnoughDiamonds from '@/components/NotEnoughDiamonds.vue'
import AppDownloadHeader from './components/AppDownloadHeader.vue'

import type { Component, Ref } from 'vue'
import type { DialogProps } from 'primevue/dialog'
import type { DynamicDialogInstance } from 'primevue/dynamicdialogoptions'
import type {
  OpenDialogPromise,
  InputDialogOptions,
  ConfirmResolveCallback,
} from '../types/global'
import { useWindowSize } from '@vueuse/core'
import { showToast } from 'vant'
import type { Reward } from '@/api/task'
import RewardDialog from '@/components/RewardDialog.vue'
import zindexMgmt from '@/utils/zindexMgmt'
import { useRoute } from 'vue-router'

const windowSize = useWindowSize()

const entrySize = ref({ width: 0, height: 0 })
const route = useRoute()

window._viewSize = entrySize

const entryRef = ref()
const enableMobileSimulator = windowSize.width.value > 1024

function onWindowResize() {
  entrySize.value.width = entryRef.value?.clientWidth ?? 0
  entrySize.value.height = entryRef.value?.clientHeight ?? 0
}

onMounted(() => {
  window.addEventListener('resize', onWindowResize)

  if (enableMobileSimulator) {
    const W = 450
    const RATIO = 1.78
    document.documentElement.style.setProperty(
      '--ld-viewport-height',
      W * RATIO + 'px'
    )
    entryRef.value.classList.add('mx-auto', 'mt-56px', 'w-450px', 'shadow-lg')
    document.body.classList.add('bg-zinc-50')
  } else {
    // 100vh 在 ios chrome 和 safari 并不是是实际视口高度，包含了底部工具栏。
    // 所以这里需要获取到实际视口高度然后设置为 css 变量来使用
    setViewportHeight()
    window.addEventListener('resize', setViewportHeight)
  }
  onWindowResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
  window.removeEventListener('resize', setViewportHeight)
})

const messageFn = () => {
  return (content: string, title?: string) => {
    showToast({
      message: title ? `${title}\n ${content}` : content,
      duration: 3000,
      className: 'toast-mobile-content',
      zIndex: zindexMgmt.nextToastZIndex(),
    })
  }
}

window._message = {
  error: messageFn(),
  success: messageFn(),
  info: messageFn(),
}
let dialogInstances: DynamicDialogInstance[] = []
const dialog = useDialog()

window._openDialog = function <R>(
  Comp: Component,
  options: {
    title?: string | Ref<string>
    props?: any
    dialog?: DialogProps
    rootClass?: string
    fullscreenInMobile?: boolean
  } = {}
): OpenDialogPromise<R> {
  const { title = '', props = {}, rootClass = '', fullscreenInMobile } = options

  let instance: DynamicDialogInstance

  const titleRef = typeof title === 'string' ? ref(title) : title

  const dialogOptions = options.dialog || ({} as any)
  if (!dialogOptions.pt) {
    dialogOptions.pt = {}
  }
  if (!dialogOptions.pt.root) {
    dialogOptions.pt.root = {}
  }
  if (!dialogOptions.pt.mask) {
    dialogOptions.pt.mask = {}
  }
  dialogOptions.pt.root.class = rootClass

  let fullscreenId: number | undefined = undefined
  if (fullscreenInMobile) {
    fullscreenId = _store.getFullscreenDialogId()
    const maskPt = dialogOptions.pt?.mask ?? {}
    maskPt[`data-id`] = fullscreenId
    dialogOptions.pt.mask = maskPt
    dialogOptions.pt.root.class += ' g-dialog-fullscreen g-safe-area'
  }

  const maskPt = dialogOptions.pt?.mask ?? {}
  maskPt.style += `;z-index: ${zindexMgmt.nextDialogZIndex()}`

  if (enableMobileSimulator) {
    maskPt.style += `;position: absolute`
  }

  const result = new Promise(resolve => {
    instance = dialog.open(
      () => {
        return h(Comp, {
          ...props,
          onDone: (val: R) => {
            resolve(val)
            instance.close()
          },
        })
      },
      {
        props: {
          modal: true,
          draggable: false,
          ...dialogOptions,
          dismissableMask: dialogOptions.dismissableMask ?? true,
          autoZIndex: false,
          appendTo: enableMobileSimulator
            ? '#app-entry'
            : dialogOptions.appendTo,
          pt: {
            ...dialogOptions.pt,
            mask: maskPt,
          },
        },
        templates: {
          header: () =>
            h(
              'h2',
              {
                class: 'text-xl font-medium',
              },
              titleRef.value
            ),
        },
        onClose() {
          dialogInstances = dialogInstances.filter(i => i !== instance)
          resolve(undefined)
        },
      }
    )
    dialogInstances.push(instance)
  }) as OpenDialogPromise<R>

  result.instance = dialogInstances[dialogInstances.length - 1]
  result.close = () => instance.close()

  // 如果进入的弹窗是全屏弹窗，则在 route query 中记录一下这个信息
  // 并且在路由返回的时候关闭弹窗
  if (fullscreenId != null) {
    const fullscreenDialogs =
      (route.query.__dialogs as string)?.split(',') ?? []
    result.fullscreenId = fullscreenId
    fullscreenDialogs.push(result.fullscreenId.toString())
    _store.fullscreenDialogInstances.add(result as any)
    _router.push({
      query: {
        __dialogs: fullscreenDialogs.join(','),
      },
    })
    result.then(() => {
      const fullscreenDialogs =
        (route.query.__dialogs as string)?.split(',') ?? []
      const index = fullscreenDialogs.indexOf(result.fullscreenId!.toString())
      if (index > -1) {
        fullscreenDialogs.splice(index, 1)

        _router.replace({
          query: {
            __dialogs:
              fullscreenDialogs.length === 0
                ? undefined
                : fullscreenDialogs.join(','),
          },
        })
      }
    })
  }

  return result
}

window._confirm = function (options): Promise<undefined> {
  return _openDialog(Confirm, {
    rootClass: 'g-dialog',
    props: {
      scene: options.scene,
      icon: options.icon,
      title: options.title,
      content: options.content,
      primaryText: options.primaryText,
      primaryIcon: options.primaryIcon,
      secondaryText: options.secondaryText,
      onPrimaryClick(cb: ConfirmResolveCallback) {
        // 如果按钮没有设置回调，则点击时直接关闭弹窗
        if (options.onPrimaryClick == null) {
          cb(true)
        } else {
          options.onPrimaryClick(cb)
        }
      },

      onSecondaryClick(cb: ConfirmResolveCallback) {
        if (options.onSecondaryClick == null) {
          cb(true)
        } else {
          options.onSecondaryClick(cb)
        }
      },
    },
    dialog: {
      showHeader: false,
      dismissableMask: false,
      autoZIndex: false,
      closeOnEscape: false,
      pt: {
        mask: {
          style: `z-index: ${zindexMgmt.nextDialogZIndex()}`,
        },
        content: {
          class: `m-4 rounded-none`,
          style: 'padding: 0px;',
        },
      },
    },
  })
}

window._notice = function (options): Promise<undefined> {
  const { onConfirm } = options

  return _openDialog(Notice, {
    props: {
      scene: options.scene,
      content: options.content,
      okText: options.okText,
      onConfirm,
    },
    dialog: {
      showHeader: false,
      contentStyle: 'padding-top: 0px;',
    },
  })
}

window._openInputDialog = function (options: InputDialogOptions) {
  let instance: DynamicDialogInstance

  const { onSubmit, placeholder, okText } = options
  const text = ref(options.text ?? '')
  const loading = ref(false)

  return new Promise<string | undefined>(resolve => {
    instance = dialog.open(
      () => {
        return h('div', [
          h(InputText, {
            class: 'w-full mb-4',
            modelValue: text.value,
            placeholder: placeholder,
            'onUpdate:modelValue': (val: string) => {
              text.value = val
            },
          }),
          h(Button, {
            class: 'w-full',
            label: okText,
            onClick: () => {
              loading.value = false

              onSubmit(text.value)
                .then((close: boolean) => {
                  if (close) {
                    resolve(text.value)
                    instance.close()
                  }
                })
                .finally(() => {
                  loading.value = false
                })
            },
          }),
        ])
      },
      {
        props: {
          header: options.title,
          modal: true,
          draggable: false,
        },
        onClose() {
          resolve(undefined)
          dialogInstances = dialogInstances.filter(i => i !== instance)
        },
      }
    )
    dialogInstances.push(instance)
  })
}

window._openBottomSheet = function (comp: Component, options) {
  return _openDialog(comp, {
    props: options?.props,
    rootClass: `w-full !m-0 ${options?.rootClass ?? ''}`,
    dialog: {
      showHeader: false,
      position: 'bottom',
      dismissableMask: options?.dialog?.dismissableMask,
      closeOnEscape: false,
      pt: {
        root: {
          style: `max-width: 100%; border-radius: 12px 12px 0px 0px; ${
            (options?.dialog?.pt as any)?.root?.style ?? ''
          }`,
        },
        content: {
          style: `border-bottom-right-radius:0px; border-bottom-left-radius:0px;  ${
            (options?.dialog?.pt as any)?.content?.style ?? ''
          } ${options?.dialog?.contentStyle ?? ''}`,
          class: 'g-safe-area',
        },
      },
    },
  })
}

window._presentContent = function (
  Comp: Component,
  options?: {
    props?: any
    bottomSheetClass?: string
    dialog?: DialogProps
  }
) {
  return _openBottomSheet(Comp, {
    props: options?.props,
    rootClass: options?.bottomSheetClass,
    dialog: options?.dialog,
  })
}

window._showRewards = function (rewards: Reward[]) {
  _openDialog(RewardDialog, {
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-4' },
      },
    },
    props: {
      rewards,
    },
  })
}

window._closeAllDialogs = function () {
  dialogInstances.forEach(i => i.shouldIgnoreByCloseAll || i.close())
  dialogInstances = []
}

window._closeActiveDialog = function () {
  const active = dialogInstances.pop()
  active?.close()
}

const RESET_TIMEOUT = 200
const TOUCH_COUNT = 5

let debugTouchCount = 0
let debugPanelOpen = false
let resetTimer: number
const handleDebugPanelTouch = () => {
  if (debugPanelOpen) return

  clearTimeout(resetTimer)
  debugTouchCount++

  if (debugTouchCount >= TOUCH_COUNT) {
    debugTouchCount = 0
    _openDebugPanel()
  }

  resetTimer = setTimeout(() => {
    debugTouchCount = 0
  }, RESET_TIMEOUT)
}
window._openDebugPanel = function () {
  if (_global.isProd) return

  if (debugPanelOpen) {
    return
  }

  debugPanelOpen = true
  import('@/components/DebugPanel/DebugPanel.vue').then(mod => {
    _openDialog(mod.default, { title: '调试面板' })
      .then(() => {
        debugPanelOpen = false
      })
      .catch(() => {
        location.reload()
      })
  })
}

window._openDiamondGetDialog = function (): Promise<void> {
  return _presentContent(NotEnoughDiamonds, {
    rootClass: 'min-w-400px max-w-600px',
    dialog: {
      contentStyle: 'padding: 0px;',
    },
  })
}

if (!_global.isProd) {
  window.addEventListener('touchstart', handleDebugPanelTouch)
}
</script>

<style>
.van-toast.toast-mobile-content {
  pointer-events: none;
  padding: 16px;
  font-size: 21px;
  color: white;
  line-height: 1.2;
  background: var(--ld-toastBg);
  border-radius: 8px;
  max-width: 306px;
  min-width: 144px;
  top: 40%;
}
</style>
