<template>
  <main class="flex flex-col h-full">
    <div
      :class="{
        'app-content': !route.meta.fullscreen,
        'h-full': route.meta.fullscreen,
      }"
    >
      <RouterView v-slot="{ Component, route }">
        <KeepAlive>
          <component
            :is="Component"
            v-if="route.meta.keepAlive"
          />
        </KeepAlive>

        <component
          :is="Component"
          v-if="!route.meta.keepAlive"
        />
      </RouterView>
    </div>

    <AppFooter
      v-if="!route.meta.fullscreen"
      class="bg-gray-100 g-safe-area"
    />
  </main>
</template>

<script setup lang="ts">
import { useCommonStore } from '@/stores'
import AppFooter from './components/AppFooter.vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
const route = useRoute()
const store = useCommonStore()

onBeforeRouteUpdate(to => {
  const fullscreenDialogs = (to.query.__dialogs as string)?.split(',') ?? []

  // 如果 query 中没有对应的 dialog id 了，就关闭掉对应的 dialog
  for (const instance of store.fullscreenDialogInstances) {
    if (
      instance.fullscreenId !== null &&
      fullscreenDialogs.every(id => parseInt(id) !== instance.fullscreenId)
    ) {
      // 如果是在 app 中，需要立即删除这个 dialog dom 元素，否则会出现 dialog 重新显示然后再消失的动画
      if (_global.isInsideApp) {
        const dialogEl = document.querySelector(
          `.p-dialog-mask[data-id="${instance.fullscreenId}"]`
        )
        dialogEl?.remove()
      }
      instance.close()
      store.fullscreenDialogInstances.delete(instance)
    }
  }
})
</script>

<style>
.app-content {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
</style>
