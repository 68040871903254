<template>
  <div class="bg-ld-background h-full flex flex-col relative">
    <SafeAreaTopSpacer class="bg-ld-background" />

    <div class="flex flex-col h-full">
      <SearchPage
        @back="onBack"
        @pkg-click="onPkgCardClick"
      />
    </div>

    <div
      v-for="(page, i) in pageFrames"
      :key="i"
      class="absolute top-0 left-0 z-1 w-full h-full flex flex-col bg-ld-background overflow-hidden g-safe-area"
    >
      <component :is="page" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { h, ref, type VNode } from 'vue'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import SearchPage from '@/components/pkg-search/SearchPage.vue'
import PublicPackage from '@/components/PublicPackage.vue'
import type { SearchedPackage } from '@/api/package-source'

// query:
//  - __frames?: 当前页面需要渲染的栈，格式为 $page:$args，使用 , 分割，args 根据不同类型自己解析
//    eg: __frames=pkg:Hxm,tag:1101...

type PkgFrame = {
  name: 'pkg'

  // 卡包的 hash id
  id: string
}

type Frame = PkgFrame

const router = useRouter()
const route = useRoute()

// 通过 __frames query 渲染的「页面」
const pageFrames = ref<VNode[]>([])

function parseFrames(framesQuery?: string): Frame[] {
  if (framesQuery == null) return []

  const items = framesQuery.split(',').filter(item => item.trim() !== '')

  return items.map(item => {
    const [name, arg] = item.split(':')

    switch (name) {
      case 'pkg':
        return {
          name: 'pkg',
          id: arg,
        }
      default:
        _global.unreachable({
          name,
        })
    }
  }) as any
}

onBeforeRouteUpdate(to => {
  const frames = parseFrames(to.query.__frames as string)

  // render
  {
    for (let i = 0; i < frames.length; i++) {
      const frame = frames[i]

      switch (frame.name) {
        case 'pkg':
          const pkgFrame = renderPublicPackagePage(frame.id)
          pageFrames.value.push(pkgFrame)
          break
      }
    }
  }

  pageFrames.value = pageFrames.value.slice(0, frames.length)
})

function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({ name: 'shelf' })
  }
}

function renderPublicPackagePage(hashId: string): VNode {
  return h(PublicPackage, {
    class: 'flex-1 overflow-hidden pt-2',
    hashId: hashId,
    showBackBtn: true,
    onBack,
    onView(pkgId: number) {
      router.push({
        name: 'package',
        params: {
          id: pkgId,
        },
      })
    },
    onChallenge: onPackageChallenge,
  })
}

function onPkgCardClick(pkg: SearchedPackage) {
  pushFrames({
    name: 'pkg',
    id: pkg.hashId,
  })
}

function pushFrames(frame: Frame) {
  let frameQuery: string = ''

  switch (frame.name) {
    case 'pkg':
      frameQuery = `pkg:${frame.id}`
      break
    default:
      _global.unreachable(frame)
  }

  const frames = (route.query.__frames as string)?.split(',') ?? []
  frames.push(frameQuery)

  router.push({
    query: {
      __frames: frames.join(','),
    },
  })
}

function onPackageChallenge(pkgId: number) {
  router.push({
    name: 'atlas',
    query: {
      pkgId,
    },
  })
}
</script>

<style scoped></style>
